import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import Button from "../Button";
import { FaArrowRight } from "react-icons/fa6";
import Header from "../header";
import Counter from "../Counter";
import Counter1 from "../Temp_Counter";
import leftImage from "../../assets/images/left_image.png";
import VideoPlayer from "../videoPlayer";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Hero = (props) => {
  const [showCounter, setShowCounter] = useState(true);

  const openPdfInNewTab = () => {
    const pdfUrl = "https://ebmavenue.io/EBM-Whitepaper.pdf";
    window.open(pdfUrl, "_blank");
  };

  return (
    <div className="tw-bg-Hero tw-bg-cover tw-relative tw-bg-black tw-bg-center tw-w-full tw-h-auto">
      <Helmet>
        <title>EBM Avenue LLC - Eco-friendly Approach to DeFi and Crypto Adoption</title>
        <link rel="canonical" href="https://ebmavenue.io/" />
        <meta name="description" content="Welcome to EBM Avenue LLC. Discover our innovative solutions and commitment to sustainability in the cryptocurrency industry." />
        <meta name="keywords" content="EBM Avenue, crypto loans, discounts, cryptocurrency, sustainability, innovation, eco-friendly, Tokenomics, $EBM, transparency, fairness, value creation, UNGC, clean energy, climate change" /> 
        <meta property="og:title" content="EBM Avenue LLC - Eco-friendly Approach to DeFi and Crypto Adoption" /> 
        <meta property="og:description" content="Welcome to EBM Avenue LLC. Discover our innovative DeFi solutions and commitment to sustainability in the cryptocurrency industry." /> 
        <meta property="og:type" content="website" /> 
        <meta property="og:url" content="https://ebmavenue.io/" /> 
        <meta property="og:image" content="https://ebmavenue.io/EBM4DeFi-1200-560.png" /> 
        <meta property="og:site_name" content="EBM Avenue LLC" /> 
        <meta name="twitter:card" content="summary_large_image" /> 
        <meta name="twitter:site" content="@ebmavenuellc" /> 
        <meta name="twitter:image" content="https://ebmavenue.io/EBM4DeFi-1200-560.png" /> 
        <meta property="og:description" content="Discover our innovative solutions and commitment to sustainability in the cryptocurrency industry. Join us on our journey towards a greener future!" />
        <meta name="twitter:description" content="Discover our innovative solutions and commitment to sustainability in the cryptocurrency industry. Join us on our journey towards a greener future!" />
        <link rel="me" href="https://twitter.com/ebmavenuellc" /> 
        <link rel="me" href="https://linkedin.com/company/ebmavenue" /> 
        <link rel="me" href="https://facebook.com/EBmavenueLLC/" /> 
      </Helmet>
      <Header />
      <div className="tw-absolute tw-top-0 tw-left-0">
        <img src={require("../../assets/images/left_image.png")} alt="EBM Avenue aqua color lighting from the left" />
      </div>
      <div className="tw-absolute tw-top-0 tw-right-0">
        <img src={require("../../assets/images/right_image.png")} alt="EBM Avenue aqua color lighting from the right" />
      </div>
      <div className="container tw-relative tw-pt-6 tw-pb-28">
        <div className="row g-8 tw-items-center">
          <div className="col-lg-6 col-md-14">
            <h1 className="tw-text-white tw-font-semibold md:tw-text-6xl tw-text-4xl">
              Welcome to Eco-Friendly{" "}
              <span className="gradient-text">Blockchain Mining Avenue !!!</span>
            </h1>
            <p className="tw-text-white tw-leading-7 tw-pt-0 tw-mb-0 tw-text-xl">
              An avenue set to promote DeFi and benefit communities. <br /><br />
              A proud supporter and ally of the United Nations Global Compact (UNGC) principles, advocating for responsible business practices in line with global sustainability goals. <br /><br />
              We don’t provide any crypto mining products or services; rather, we offer exclusive crypto discounts, staking rewards, and interest-free crypto loans. <br /><br />
              Join us in this fulfilling journey - where every step leaps towards a better world.
            </p>
            <div className="tw-flex tw-gap-7 sm:tw-justify-start tw-justify-center tw-mb-1">
              <Button
                onClick={openPdfInNewTab}
                label={"White Paper"}
                className={"tw-mt-7"}
                rIcons={<FaArrowRight color="white" />}
              />
              <Button
                onClick={() => setShowCounter(!showCounter)}
                label={showCounter ? "Learn More" : "Get $EBM"}
                className={"tw-mt-7"}
                rIcons={<FaArrowRight color="white" />}
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-12 tw-flex tw-justify-end tw-mb-0">
            {showCounter ? (
              <div className="tw-bg-[#282E32] tw-border-[#269FF0] tw-border-2 tw-rounded-2xl tw-h-auto tw-bg-cover bg-hero-cut" style={{ width: '510px', overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <iframe className="tw-rounded-2xl" width="500" height="580" frameBorder="0" scrolling="no" src="https://gempad.app/embed?address=0xC2E1c4Aa64e4eef79D3beC03A5f8F9948b4c58bD&bgColor=%2312181F&fgColor=%23161D27&hasAnimation=true&hasBanner=true&hasSocialLinks=true&network=BNB_Smart_Chain&padding=20&refer=0xdd1ECbb8aAC2Fb3109f20c85012B49248cdfe8ca&responsive=true&saleType=presale&theme=dark&txtColor=%23FFFFFF"></iframe>
              </div>
            ) : (
              <VideoPlayer src={require("../../assets/images/ebm.mp4")} thumbnail={require("../../assets/images/EBMExplained2.png")} />
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Hero;
