import React, { useState } from "react";

const VideoPlayer = ({ src, thumbnail }) => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  const loadVideo = () => {
    setIsVideoLoaded(true);
  };

  return (
    <div className="video-player" style={{ position: "relative", cursor: "pointer" }}>
      {!isVideoLoaded ? (
        <img
          src={thumbnail}
          alt="Video thumbnail"
          style={{ width: "100%", borderRadius: "10px" }}
          onClick={loadVideo}
        />
      ) : (
        <video controls src={src} poster={thumbnail} style={{ width: "100%", borderRadius: "10px" }} />
      )}
    </div>
  );
};

export default VideoPlayer;
