import React, { useState } from 'react'
import Accordion from '../Accordion/Accordion';

const FAQ = () => {
    const [accordions, setAccordion] = useState([
        {
          key: 1,
          title:
            "1. How many $EBM I need to be eligible in Swap4Less?",
          data: <p>Through Swap4Less, $EBM holders can purchase various cryptocurrencies at discounted rates ranging from 0.1% to 10% basing on monthly average $EBM holdings. For example: Holding 10 tokens will offer 0.1 % discount.<br></br><br></br> Note: This will be announced publicly on our social channels on monthly basis and also on Swap4Less DEX page.</p>,
          isOpen: false,
        },
        {
          key: 2,
          title:
            "2. What is Staking during Presale?",
            data: "This staking opportunity is exclusive to the presale investors to grow their investment with native $EBM token rewards during the presale period. This option will not be available after listing $EBM",
            isOpen: false,
        },
        {
          key: 3,
          title:
            "3. What are benefits of $EBM to the Community?",
            data: "Other than Swap4Less and Interest free crypto loans, EBM community who stake their EBM tokens can earn additional benefits and rewards through our Stake4More program. ",
            isOpen: false,
        },
        {
          key: 4,
          title:
            "4. What is Stake4More?",
            data: "By staking $EBM tokens at Stake4More, instead of rewarding with native EBM tokens, this will reward with higher discounts and rewards from company profits",
            isOpen: false,
        },
        {
          key: 5,
          title: "5. How to participate in Stake4More?",
          data: "Upon starting of operations, the program start will be announced once its smart contract is deployed, tested and audited.",
          isOpen: false,
        },
        
        {
          key: 6,
          title: "6. What are $EBM token value dump related security measures? ",
          data: "$EBM will be listed on multiple DEX (Pancake, Uniswap, Radium etc.) and CEX giving opportunity to those who missed the presale ",
          isOpen: false,
        },
        
        {
            key: 7,
            title: "7. Maintaining $EBM value in the long term?",
            data: <p> EBM with their visionary team and experienced advisory board aim to come up with strategic EBM utilities to maintain long term token value and appreciation. <br></br>We strongly advise Stake, HODL $EBM from day one for native token rewards and to reap benefits from Swap4Less and Stake4More programs.</p>,
            isOpen: false,
          },
        
          {
            key: 8,
            title: "8. Maintaining $EBM value by Token Locks?",
            data: <p> EBM Tokens allocated for our development operations, marketing, teams and advisors are locked with linear vesting for periods from 2- 5 years can be verified on <a href="https://gempad.app/locks/project?token=0x43ED084aaDC897FF94F41d0DAc02a9571dC8339F&network=BNB_Smart_Chain" className="text-gradient tw-underline"> Gempad Locks</a> </p>,
            isOpen: false,
          },
          {
            key: 9,
            title: "9. Has the project undergone any audits or security assessments?",
            data: <p>Yes, our smart contract audits can be verified here on <a href="https://www.cyberscope.io/audits/ebm" className="text-gradient tw-underline"> Cyberscope</a></p>,
            isOpen: false,
          },
      ]);
    
      const toggleAccordion = (accordionkey) => {
        const updatedAccordions = accordions.map((accord) => {
          if (accord.key === accordionkey) {
            return { ...accord, isOpen: !accord.isOpen };
          } else {
            return { ...accord, isOpen: false };
          }
        });
    
        setAccordion(updatedAccordions);
      };
  return (
    <div className=" tw-bg-black  tw-overflow-x-hidden   tw-bg-no-repeat tw-w-full tw-bg-cover   tw-h-auto tw-py-20">
       
      <div className=" container">
      <div className='row'>
        <div className='col-lg-6 col-md-12'>
        <h2 className=" tw-font-medium tw-text-4xl sm:tw-text-start tw-text-center  tw-text-white">Frenquently Questions</h2>
        <div className="  tw-mt-12">
          {accordions.map((accordion) => (
            <Accordion
              key={accordion.key}
              title={accordion.title}
              data={accordion.data}
              isOpen={accordion.isOpen}
              toggleAccordion={() => toggleAccordion(accordion.key)}
            />
          ))}
        </div>
        </div>
        <div className="col-lg-6 col-md-12  md:tw-pt-44 tw-pt-0 tw-relative">
            <img src={require("../../assets/images/bg-banner.png")} alt= "background banner" className='  md:tw-block tw-hidden' />
            <div className="row md:tw-absolute tw-relative -tw-top-12">
              <div className="col-md-12 tw-pt-36 tw-mx-auto">
               <div className='row'>
                <div className='col-md-10 tw-mx-auto'>
                <img
                  src={require("../../assets/images/faq.png")}
                  alt = "Bitcoin mining"
                  className=" tw-w-full"
                />
                </div>
               </div>
              </div>
            </div>
           
          </div>
      </div>
      </div>
    </div>
  )
}

export default FAQ
