import React from "react";
import Header from "../../components/header";
import { Helmet } from "react-helmet-async";
import { FaArrowRight } from "react-icons/fa6";
import Button from "../../components/Button";
import Footer from "../../components/footer";

const Stake4More = () => {
  return (
    <div className=" tw-bg-center tw-overflow-x-hidden  tw-relative  tw-bg-black tw-bg-cover tw-w-full lg:tw-h-screen tw-h-auto">
      <Helmet>
      <title>Stake4More: Maximize Your EBM Token Benefits </title>
      <meta name = "description" content ="Join EBM Avenue's Stake4More program to earn higher discounts and daily rewards by staking your EBM tokens. Benefit from smart contract security and pro rata distribution." />
      <meta name="keywords" content="EBM Avenue, Stake4More, $EBM tokens, crypto staking, higher discounts, daily rewards, smart contract, pro rata distribution, sustainable crypto" /> 
      <link rel="canonical" href="https://ebmavenue.io/stake4more" />
      <meta property="og:type" content="website"/> <meta property="og:url" content="https://ebmavenue.io/stake4more"/> <meta property="og:image" content="https://ebmavenue.io/EBM4DeFi-1200-560.png"/>
      </Helmet>
      <Header />
      <div className=" tw-absolute tw-top-0 tw-left-0">
        <img src={require("../../assets/images/left_image.png")} alt = "Left light image" />
      </div>
      <div className="container  tw-py-24">
        <div className="row   g-5 tw-items-center">
          <div className="col-md-6">
            <h1 className="  tw-text-center tw-text-white  gradient-text tw-font-semibold tw-text-[45px]">
              Stake 4 More
            </h1>
            <p className=" tw-text-white sm:tw-text-start tw-text-center  tw-leading-7 tw-pt-4 tw-text-xl">
            Our Stake for more program reward participants who stake their EBM tokens. 
            By staking, participants can earn additional benefits and rewards. 
            </p>
            <h2 className=" sm:tw-text-start tw-text-center tw-text-white  gradient-text tw-font-semibold tw-text-[40px]">
            Higher Discounts           </h2>
            <p className=" sm:tw-text-start tw-text-center tw-text-white  tw-leading-7 tw-pt-4 tw-text-xl">
            When you participate in Stake4More, you receive higher discounts when using the Swap4Less program. This means that the more you stake, the greater the discounts you can enjoy on cryptocurrency transactions.
                     
            </p>
            <h2 className=" sm:tw-text-start tw-text-center tw-text-white  gradient-text tw-font-semibold tw-text-[40px]">
            Rewards from Profits           </h2>
            <p className=" sm:tw-text-start tw-text-center tw-text-white  tw-leading-7 tw-pt-4 tw-text-xl">
            EBM Avenue allocates a portion of its monthly profits to the Stake4More rewards pool. These profits are added to a smart contract, which automatically distributes rewards to participants
                     
            </p>
            <h2 className=" sm:tw-text-start tw-text-center tw-text-white  gradient-text tw-font-semibold tw-text-[40px]">
            Pro Rata Distribution           </h2>
            <p className=" sm:tw-text-start tw-text-center tw-text-white  tw-leading-7 tw-pt-4 tw-text-xl">
            Rewards are distributed daily on a pro rata basis. This means that the rewards are divided among participants based on the amount they have staked. The more you stake, the larger your share of the rewards.
                     
            </p>
            
           
          </div>
          <div className="col-lg-6 col-md-12">
                                 
              <a href="https://x.com/ebmavenuellc" target="_blank" rel="noopener noreferrer"/>
                <img
                  src={require("../../assets/images/STAKE4MORE.webp")}
                  alt = "EBM Staking benefits"
                  className=""
                />
             
                       
          
        </div>
        </div>
          
        <div className="tw-text-center ">
            
            <h2 className=" tw-text-center tw-text-white  gradient-text tw-font-semibold tw-text-[40px]">
            Key Benefits          </h2>                  
            <li className=" tw-text-center tw-text-green  gradient-text tw-font-semibold tw-text-[30px]">
            Increased Discounts: 
            </li>
            <p className=" tw-text-center tw-text-white  tw-leading-7 tw-pt-4 tw-text-xl">Enjoy better rates on Swap4Less transactions.</p>
            <li className=" tw-text-center tw-text-green  gradient-text tw-font-semibold tw-text-[30px]">
            Daily Rewards: 
            </li>
            <p className="  tw-text-center tw-text-white  tw-leading-7 tw-pt-4 tw-text-xl">
            Earn a share of EBM Avenue's profits every day.</p>
            <li className=" tw-text-center tw-text-green  gradient-text tw-font-semibold tw-text-[30px]">
            Smart Contract Security: 
            </li>
            <p className="  tw-text-center tw-text-white  tw-leading-7 tw-pt-4 tw-text-xl">
            The use of smart contracts ensures that the reward distribution is transparent and secure.</p>
                
            
          
          </div>
        <div className=" tw-bg-center tw-overflow-x-hidden   ">
        <div className=" tw-flex  tw-justify-center">
          <a href="https://x.com/EBMAvenueLLC" target="_blank" rel="noopener noreferrer">
            <Button
              
              label={"EBM Community on X"}
              rIcons={<FaArrowRight color="white" />}
              
            />
            </a>
            </div>
          <p className="  tw-text-center tw-text-white  tw-leading-7 tw-pt-4 tw-text-xl">
          Join us in making a positive impact while enjoying the benefits of our Stake4More program </p>
          
          </div>
            
          </div>
      
      <Footer/>
    </div>
  );
};

export default Stake4More;
