import React from 'react'
import {Helmet}  from 'react-helmet-async'
import Header from '../../components/header'
import Footer from '../../components/footer'
const OurVission = () => {
  return (
    <>
    <Helmet>
<title>Our Vision - EBM Avenue LLC</title>
<link rel="canonical" href="https://ebmavenue.io/vision" />
<meta name="description" content="Discover the vision of EBM Avenue LLC in revolutionizing cryptocurrency mining with sustainable practices and community-driven initiatives." />
<meta name="keywords" content="EBM Avenue, UN initiatives, vision, best cryptocurrency, 100x crypto, sustainability, innovation, community-driven, crypto utility" />
<meta property="og:title" content="Our Vision - EBM Avenue LLC" />
<meta property="og:description" content="Discover the vision of EBM Avenue LLC in revolutionizing cryptocurrency mining with sustainable practices and community-driven initiatives." />
<meta property="og:type" content="website"/> <meta property="og:url" content="https://ebmavenue.io/vision"/> <meta property="og:image" content="https://ebmavenue.io/JOIN.webp"/>

</Helmet>




    <div className=' tw-bg-center   tw-relative  tw-bg-black tw-bg-cover tw-w-full  tw-h-auto'>
    <Header/>
      <div className=' container tw-py-16 tw-mx-auto'>


        <div  className=' row'>
            <div className='col-md-7'>
            <h1 className=" tw-flex tw-gap-2 tw-items-center tw-text-white  gradient-text tw-font-semibold md:tw-text-[45px] tw-text-[35px]">
            
             Vision  <p className=' m-0  tw-bg-[#2596EF] tw-h-[1px]  tw-w-80'></p>
          
            </h1>
            <ul  className=' tw-leading-8 p-0'>
            <li>
              <p className=' tw-text-white sm:tw-text-left tw-text-center'>At EBM Avenue, we are committed to revolutionizing the cryptocurrency industry by promoting DeFi and benefiting communities through sustainable practices. As proud supporters of the United Nations Global Compact (UNGC) principles, we advocate for responsible business practices aligned with global sustainability goals.
              </p>
            </li>
            
            <li>
              <p className=' tw-text-white sm:tw-text-left tw-text-center'> <span className="gradient-text tw-text-2xl">Vision for Sustainable Mining: </span>In the realm of cryptocurrency mining, EBM Avenue aims to set new benchmarks for efficiency and sustainability. We envision a future where mining is not only profitable but also environmentally conscious. By utilizing renewable energy sources, modern hardware, and profit-switching mechanisms, we strive to minimize our carbon footprint while maximizing output.
              </p>
            </li>
            <li>
              <p className=' tw-text-white sm:tw-text-left tw-text-center'> <span className="gradient-text tw-text-2xl">Commitment to Global Sustainability: </span>Our core values are deeply rooted in sustainability, innovation, and inclusivity. We actively participate in global initiatives to address environmental, social, and economic challenges. Our alignment with the UN Sustainable Development Goals (SDGs) and the UN Environment Program Finance Initiative (UNEP FI) underscores our dedication to reducing carbon emissions and fostering green technology..
              </p>
            </li>
        </ul>
            </div>
            <div className='col-md-5'>
              <div className='' >
              <img src={require('../../assets/images/Union.png')} alt = "Wrist Watch and showing Bitcoin"   className=' tw-w-full' style={{ height:"580px" }} />
              </div>
            </div>
        </div>

        <div className=' tw-gap-4 tw-items-center tw-my-6 sm:tw-flex tw-block tw-border-2 tw-rounded-2xl tw-p-5  tw-w-full tw-border-[#2596EF]'>
          <div className=' sm:tw-border-r-2 tw-border-0 tw-border-[#2596EF]'>
          <div className=' tw-px-8'>
          <img src={require('../../assets/images/third_logo.png')} alt="EBM Avenue Logo" className=' sm:tw-w-80 tw-w-32 tw-mx-auto' />
          </div>
          </div>
          <div className=' sm:px-2 tw-px-0'>
            <p className=' tw-text-white tw-pt-2 sm:tw-text-left tw-text-center  tw-text-xl'>Our vision transcends traditional mining methods by empowering individuals to benefit from token holding and staking for exclusive discounts and rewards.  
By enhancing crypto utility and elevating $EBM as the premier cryptocurrency, we aim to lead the industry in sustainable and inclusive innovation making $EBM an all time best cryptocurrency.
</p>
          </div>
        </div>
        <ul  className=' tw-leading-8 p-0'>
            
            <li>
              <p className=' tw-text-white sm:tw-text-left tw-text-center'> <span className="gradient-text tw-text-2xl">Empowering Communities: </span>EBM Avenue is committed to promoting gender equality and human rights. We support the UN Women’s Empowerment Principles (WEPs) and various Human Rights Initiatives, ensuring equal opportunities and a fair environment for all.
              </p>
            </li>
            <li>
              <p className=' tw-text-white sm:tw-text-left tw-text-center'><span className="gradient-text tw-text-2xl"> Vision for the Future: </span>Our vision extends beyond traditional mining approaches. We aim to enable individuals to participate in green mining initiatives and benefit through token holding and staking. By supporting global UN initiatives and fostering business partnerships, we strive to promote 100x crypto adoption and enhance the utility of $EBM, positioning it as the best cryptocurrency to buy.
              </p>
            </li>
        </ul>
      </div>
    </div>

    <Footer/>
    </>
  )
}

export default OurVission
