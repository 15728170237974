import React from "react";
import Header from "../../components/header";
//import { FaArrowRight } from "react-icons/fa6";
//import Button from "../../components/Button";
import Footer from "../../components/footer";
import { Helmet } from "react-helmet-async";

const Loans4Free = () => {
  return (
    <div className=" tw-bg-center tw-relative  tw-overflow-x-hidden tw-bg-black tw-bg-cover tw-w-full lg:tw-h-screen tw-h-auto">
      <Helmet>
    <title>Loans4Free: EBM Avenue's Interest-Free Loans via DeFi</title>
    <link rel="canonical" href="https://ebmavenue.io/Loans4Free" />
    <meta name="description" content="Explore EBM Avenue's Loans4Free program offering interest-free loans to EBM token holders. Leverage our DApp for secure and transparent transactions with fixed daily fees." />
    <meta name="keywords" content="EBM Avenue, interest-free loans, Loans4Free, EBM token, DeFi, smart contracts, crypto lending, fixed fees, financial empowerment" />
    <meta property="og:type" content="website" /> <meta property="og:url" content="https://ebmavenue.io/Loans4Free" /> 
    <meta property="og:image" content="https://ebmavenue.io/EBM4DeFi-1200-560.png"/>
    </Helmet>
      <Header />
      <div className=" tw-absolute tw-top-0 tw-left-0">
        <img src={require("../../assets/images/left_image.png")} />
      </div>
      <div className="container tw-py-28 tw-mt-0 tw-mb-4">
        <div className="row  g-5  tw-items-center">
          <div className="col-md-6">
            <h1 className=" sm:tw-text-start tw-text-center tw-text-white  gradient-text tw-font-semibold tw-text-[45px]">
            Intrest Free Loans            </h1>
            <p className=" sm:tw-text-start tw-text-center tw-text-white  tw-leading-7 tw-pt-4 tw-text-xl">
            EBM Avenue is pioneering a new era in crypto lending with its first Decentralized Application (DApp), offering interest-free loans to $EBM token holders. <br>
            </br><br>
            </br>This innovative platform leverages smart contracts to ensure secure and transparent transactions.
            </p>
            <h2 className=" sm:tw-text-start tw-text-center tw-text-white  gradient-text tw-font-semibold tw-text-[40px]">
            Program Details            </h2>
            <p className=" sm:tw-text-start tw-text-center tw-text-white  tw-leading-7 tw-pt-4 tw-text-xl">
            Our DApp utilizes smart contracts to automate and secure the loan process, ensuring trust and efficiency.
            </p>
            <h2 className=" sm:tw-text-start tw-text-center tw-text-white  gradient-text tw-font-semibold tw-text-[40px]">
            Benefits           </h2>
            <p className=" sm:tw-text-start tw-text-center tw-text-white  tw-leading-7 tw-pt-4 tw-text-xl">
            Community members can borrow the required amount by providing the necessary collateral and selecting their preferred loan duration. 
                     
            </p>
                   

            
           
          </div>
          <div className="col-lg-6 col-md-12">
          
              
              <a href="https://facebook.com/EBMAvenuellc" target="_blank" rel="noopener noreferrer"/>
              
                <img
                  src={require("../../assets/images/IFL.webp")}
                  alt = "EBM Interest free Loans"
                  style={{ height: '500px' }}
                />
              
                  
          
          </div>
          
        </div>
        <div className="tw-text-center tw-mt-4 tw-mb-0 ">
              
            <h2 className="  tw-text-center tw-text-white  gradient-text tw-font-semibold tw-text-[40px]">
            Key Features          </h2>                  
            <li className=" tw-text-center tw-text-green  gradient-text tw-font-semibold tw-text-[30px]">
            Fixed Daily Platform Fee:: 
            </li>
            <p className=" tw-text-center tw-text-white  tw-leading-7 tw-pt-4 tw-text-xl">Instead of traditional interest, a fixed daily fee is applied, offering predictability and transparency.</p>
            <li className=" tw-text-center tw-text-green  gradient-text tw-font-semibold tw-text-[30px]">
            Flexible Terms:
            </li>
            <p className=" tw-text-center tw-text-white  tw-leading-7 tw-pt-4 tw-text-xl">
            Borrowers have the freedom to choose their loan duration, tailored to their financial needs.</p>
            <li className=" tw-text-center tw-text-green  gradient-text tw-font-semibold tw-text-[30px]">
            Empowerment: 
            </li>
            <p className=" tw-text-center tw-text-white  tw-leading-7 tw-pt-4 tw-text-xl">
            Access funds securely and efficiently, leveraging your $EBM tokens with public smart contracts that ensures transparency and security.</p>
                        
            
          
          </div>
        
      </div>
      <Footer/>
    </div>
  );
};

export default Loans4Free;
