import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Brands = () => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  const brands = [
    { src: 'EBM-Avenue-in-Blockchain-wire.png', href: 'https://blockchainwire.io/press-release/ebm-avenue-llc-a-new-eco-friendly-approach-to-defi-and-crypto-adoption' , alt : 'EBM Avenue in Blockchain wire' },
    { src: 'EBM-Avenue-in-Business-Insider2.png', href: 'https://markets.businessinsider.com/news/stocks/ebm-avenue-llc-a-new-eco-friendly-approach-to-defi-and-crypto-adoption-1033844084', alt : 'EBM Avenue in Business insider' },
    { src: 'EBM-on-Gempad.png', href: 'https://gempad.app/presale/0xC2E1c4Aa64e4eef79D3beC03A5f8F9948b4c58bD?network=BNB_Smart_Chain', alt: 'EBM on Gempad' },
    { src: 'EBM-Avenue-in-Morning-Star.svg', href: 'https://www.morningstar.com/news/globe-newswire/9255885/ebm-avenue-llc-a-new-eco-friendly-approach-to-defi-and-crypto-adoption', alt : 'EBM Avenue in Morning Star' },
    { src: 'EBM-Avenue-in-Market-Watch1.png', href: 'https://www.marketwatch.com/press-release/ebm-avenue-llc-a-new-eco-friendly-approach-to-defi-and-crypto-adoption-a9d9eb5c', alt : 'EBM Avenue in Market Watch' },
    { src: 'EBM-Avenue-in-Trading-view.svg', href: 'https://www.tradingview.com/news/reuters.com,2024-10-14:newsml_GNXp09vQ:0-ebm-avenue-llc-a-new-eco-friendly-approach-to-defi-and-crypto-adoption/', alt : 'EBM Avenue in Trading View' },
    { src: 'EBM-Avenue-in-Street-Insider.png', href: 'https://www.streetinsider.com/Globe+Newswire/EBM+Avenue+LLC%3A+A+New+Eco-Friendly+Approach+to+DeFi+and+Crypto+Adoption/23832544.html', alt : 'EBM Avenue in Street Insider' },
    { src: 'EBM-Avenue-in-ADVFN.svg', href: 'https://www.advfn.com/stock-market/stock-news/94713723/ebm-avenue-llc-a-new-eco-friendly-approach-to-def', alt : 'EBM Avenue in ADVFN news' },
    
    { src: 'brand9.png', href: 'https://example.com/link9', alt: 'EBM partner with Antminer' },
  ];

  return (
    <div className="tw-h-32 tw-bg-[#101010] tw-flex tw-items-center">
      <div className="container tw-mx-auto">
        <Slider {...settings}>
          {brands.map((brand, index) => (
            <div key={index} className="tw-flex tw-items-center tw-justify-center tw-h-28 tw-px-5">
              <a href={brand.href} target="_blank" rel="noopener noreferrer">
                <img
                  src={require(`../../assets/images/${brand.src}`)}
                  className="tw-object-contain tw-h-32"
                  alt={`Brand ${index + 1}`}
                />
              </a>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Brands;
